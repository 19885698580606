import { config } from '../config';

import Icon from '@mdi/react';
import { mdiHelpCircleOutline, mdiCogOutline } from '@mdi/js';

export default function NavigatorBottom ({ helpText }) {

  return (
    <div className="nav-bottom">

      <dir className='helper-text fo-gm'>
        { helpText }
      </dir>

      <div className='nav-icons'>
        <Icon className='nav-icons' path={ mdiHelpCircleOutline } size={ 2 } />
        <Icon className='nav-icons' path={ mdiCogOutline } size={ 2 } />
      </div>

    </div>
  );
}
