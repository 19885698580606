// imgs
import dustBg from '../d_images/dust_bg.webp';

export default function BgSlide ({ offset })
{
  return (
    <>
      <div

        className = 'bg-slide'
        style     = {{
          backgroundImage: `url(${dustBg})`,
          transform: `translateX( ${offset}vw )`
        }}

      > </div>
    </>
  );
}
