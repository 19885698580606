// views
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { config } from '../config';
//
import NavigatorBottom from './Navigator_Bottom';
import { friendlyToken, certinfo } from '../utils';
import BgSlide from './Bg_Slide';
// imgs
import bigLoading from '../d_images/bigLoading.webp';
import mainLogo from '../d_images/mainLogo.webp';
import CryptoPill from '../d_frames/CryptoPill';
import BigButton from '../d_frames/BigButton';
import DataLine from '../d_frames/DataLine';
// actions
import CertFunctions from '../action/CertFunctions';
import Alert from '../d_actions/Alert';

export default function Certificate_Edit ( ) {

  const nav = useNavigate();
  const { cert } = useParams();

  const [ certData, setCertData ] = useState( null );
  const [ alert, setAlert ] = useState({ type:'pending', msg:null });

  useEffect(() => {

    CertFunctions( 'get-certificate', cert,
      er => {
        setAlert(er);
        setTimeout(() => {
          nav( '/' );
        }, 3000);
      },
      re => {
        setCertData( re );
        resetAlert();
      });

  }, [ nav, cert ]);

  return (
    <>
    < BgSlide offset={ -100 } />
    <div className='cont-main'>

      <img className='logo-top' alt='geko' src={ mainLogo } />

      { alert.type === 'pending' ?

        <>
          <div className='logo-center'>
            <img className='App-logo' alt='geko' src={ bigLoading } />
          </div>
        </>

        :

        <>
          <div className='cont-slider'>
            <div className='data-center'>

              <div className='section-title'> operazioni lingotto: </div>
              <DataLine title='Seriale :' data={ friendlyToken( certData.cryptoID, 8 ).slice(0,19) + ' ...' } />

              <div className='button-row'>

                <BigButton
                  text='attiva certificato'
                  color='bg-success'
                  _action={ () => activate() }
                />

                <BigButton
                  text='disattiva certificato'
                  color='bg-danger'
                  _action={ () => deactivate() }
                />

              </div>

              <div className='button-row'>

                <BigButton
                  text='Scansiona nuovo'
                  color='bg-muted'
                  _action={ () => gotoScan() }
                />

              </div>

              <div className='section-divider'></div>

              <div className='section-title'>Stato corrente</div>

              <CryptoPill weight='1000' type='Oro' state={ certData.state }/>

            </div>
          </div>

          < NavigatorBottom helpText={ 'Esplora i dati del certificato' } />
        </>
      }

      < Alert current_alert={ alert } />

      <div className="simple-foot">{config.app.foot}<span className='fo-gt co-gold'>{config.app.version}</span></div>
    </div>
    </>
  );

  function activate (  )
  {
    if ( certData.state === certinfo.states.Active ) { return true; }
    CertFunctions ( 'activate-certificate', certData.cryptoID,
      er => { setAlert(er) },
      re => { get_certificate(); });
    return;
  }

  function deactivate (  )
  {
    if ( certData.state === certinfo.states.Inactive ) { return true; }
    CertFunctions ( 'deactivate-certificate', certData.cryptoID,
      er => { setAlert(er) },
      re => { get_certificate(); });
    return;
  }

  function gotoScan (  )
  {
    nav( '/' );
    return;
  }

  function get_certificate (  )
  {
    CertFunctions( 'get-certificate', cert,
      er => {
        setAlert(er);
        setTimeout(() => {
          nav( '/' );
          return;
        }, 3000);
      },
      re => {
        setCertData( re );
        console.log(re);
        resetAlert();
        return;
      });
  }

  function resetAlert (  ) {
    setAlert({ type:'ok' });
    return;
  }

}
