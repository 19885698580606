import qrIcon from '../d_images/qrIcon.webp';

import Icon from '@mdi/react';
import { mdiCamera } from '@mdi/js';

import { Scanner } from '@yudiel/react-qr-scanner';
import { useState } from 'react';

export default function Qrscan ({ scan, _onSuccess }) {

  return (
    <>
    <div className="qrscan-cont">

      { scan ?

        < Scanner
          styles= {{
            container: {
              width: "100%",
              height: "100%",
              transform: 'scale(2)',
              borderRadius:"8px"
            }
          }}
          components = {{
            finder: false
          }}
          scanDelay= { 500 }
          onScan= { ( re ) => _onSuccess( re ) }
          />

      :
        <div className='qr-cont'>
          <div className='qr-icon' style={{ backgroundImage: `url(${qrIcon})` }}>
            <Icon className='material-icons icon' path={mdiCamera} size={2} />
          </div>
        </div>
      }

    </div>
    </>
  );

}
