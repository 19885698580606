import { useNavigate } from "react-router-dom";

export const certinfo = {
  states : {
    Inactive : '00',
    Active   : '01',
    Public   : '02',
    Sold     : '03',
    Resold   : '04',
    Orphan   : '05',
    NFT      : '06',
    Burned   : '07'
  },

  get_cert_state ( state_id ) {
    switch( state_id ) {

      case this.states.Inactive:
        return 'Inattivo';
      case this.states.Active:
        return 'Attivo';
      case this.states.Public:
        return 'Pubblico';
      case this.states.Sold:
        return 'Venduto';
      case this.states.Resold:
        return 'Rivenduto';
      case this.states.Orphan:
        return 'Orfano';
      case this.states.NFT:
        return 'NFT';
      case this.states.Burned:
        return 'Bruciato';

      default:
        return 'none';
    }
  },

  get_client_cert_state ( state_id ) {
    switch( state_id ) {

      case this.states.Inactive:
        return 'Inattivo';
      case this.states.Active:
        return 'Attivo';
      case this.states.Burned:
        return 'Bruciato';

      default:
        return 'Crypto';
    }
  }

};

export function friendlyToken( token, limit )
{

  if ( token == null ) {
    return 'non presente';
  }

  let newToken = '';
  let len = limit;
  let counter = 0;
  for  ( let i = 0; i < token.length; i++ ) {
    if ( counter === len ) {
      newToken += ' - '+token[i];
      counter = 0;
    } else {
      newToken += token[i];
    }
    counter ++
  }
  return newToken;
}
