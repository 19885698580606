export default function BigButton ({ text, color, _action })
{
  return (
    < div
      className={ `fo-gbb big-button ${color}` }
      onClick={ _action }>
      { text }
    </div>
  )
}
