import Fetcher from '../d_actions/Fetcher';

const apiEnv = 'cert';

export default async function CertFunctions ( operation, crypto_id, _on_error, _on_success )
{
    if ( operation === '' || operation == null ) {
        return _on_error({ type:'error', msg:'operation is null' });
    }

    Fetcher( { op:operation, certID:crypto_id }, apiEnv)
    .then( re => {

        if ( re.status === 'ko') {
            return _on_error({ type:'error', msg:re.response });
        } else {
            return _on_success( re );
        }

    }).catch( err => {
        return _on_error({ type:'error', msg:err });
    });
}
