import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/App.scss';
import Splash from './view/Splash';
import Certificate_Edit from './view/Certificate_Edit';

function App() {

  return (
    <BrowserRouter>
      <div className="App fo-gl">
        <Routes>

          <Route
            exact
            path='/'
            element={ <Splash /> }
          />

          <Route
            exact
            path='/:cert'
            element={ <Splash /> }
          />
          
          <Route
            exact
            path='cert/:cert'
            element={ <Certificate_Edit /> }
          />:q


        </Routes>
      </div>
    </BrowserRouter>
  );

}

export default App;
