import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

// views
import { config, texts } from '../config';
import Bg_Slide from '../view/Bg_Slide';
import Qrscan from '../d_actions/Qrscan';
import Navigator_Bottom from './Navigator_Bottom';

// imgs
import mainLogo from '../d_images/mainLogo.webp';
import bigLoading from '../d_images/bigLoading.webp';





export default function Splash ()
{
  const nav = useNavigate();
  const { cert } = useParams();

  const [ bgOffset, setBgOffset ] = useState( 0 );
  const [ canScan, setCanScan ] = useState( false );
  const [ navMessage, setNavMessage ] = useState( texts( 'scan-before' ) );
  const [ alert, setAlert ] = useState( 'loaded' );

  useEffect(() => {
    if ( cert != null ) {
      nav( '/cert/' + cert );
    }
  }, [ nav, cert ]);

  return (
    <>

    <div className='cont-main'>

      <img className='logo-top' src={ mainLogo } />

      { alert === 'pending' ?

        <>
          <div className='logo-center'>
            <img className='App-logo' src={ bigLoading } />
          </div>
        </>

        :

        <>
          <div className='logo-center' onClick={() => switchScan() }>
            < Qrscan
              _onSuccess={ ( d ) => on_qr_scan_success( d ) }
              message={ `Scan me` }
              scan={ canScan }
              />
          </div>

          < Navigator_Bottom helpText={ navMessage } />
        </>
      }


      <div className="simple-foot">{config.app.foot}<span className='fo-gt co-gold'>{config.app.version}</span></div>
    </div>
    </>
  );

  function on_qr_scan_success ( data )
  {
    let tok = data[0].rawValue.slice( -16 );
    console.log( tok );
    nav( `/cert/${tok}` );
  }

  function switchScan ( )
  {
    canScan ? setCanScan( false ) : setCanScan( true );
    if ( canScan ) {
      console.log('setting scan to false');
      setNavMessage( texts( 'scan-before' ) );
    } else {
      console.log('setting scan to true');
      setNavMessage( texts('scan-during') );
      setTimeout(() => {
        setCanScan( false );
        setNavMessage( texts('scan-before') );
      }, 10000);
    }
  }

}
