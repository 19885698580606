import Icon from '@mdi/react';
import { mdiArrowRightThin } from '@mdi/js';
import { certinfo } from '../utils';


export default function CryptoPill ({ weight, type, state })
{
  return (
    <>
      <div className="pill-container">

        <div className="left-side">
          <div>
          { `${weight} Gr.` }
          </div>
        </div>

        <div className="right-side">

          <div className="type">
            { state &&
              <div>
                { certinfo.get_cert_state( state ) + ` | ${type}` }
              </div>
            }
          </div>

          <Icon path={mdiArrowRightThin} size={1} />

        </div>
      </div>
    </>
  );
}

